<template>
    <div class='teacher-salaries-accordion'>
        <div class='tsmp-students-header bg-secondary text-white font-weight-bold'>
            <div>Преподаватель</div>
            <div class='text-center'>Оклад</div>
            <div class='text-center'>Донаты</div>
            <div class='text-center'>Премии</div>
            <div class='text-center'>Штрафы</div>
            <div class='text-center'>Полная загрузка</div>
            <div class='text-center'>Выплачено</div>
            <div class='text-center'>Задолженность</div>
            <div class='text-center'>Действие</div>
        </div>
        <div
            v-for='(teacher, idx) in teachers'
            :key='idx'
            class='tsmp-students-body-row border-bottom border-gray'
        >
            <div class='tsmp-students-body-grid'>
                <div>{{ teacher.name }}</div>
                <div class='text-center'>{{ teacher.salary }} {{ teacher.currency }}</div>
                <div class='text-center'>{{ teacher.donate }} {{ teacher.currency }}</div>
                <div class='text-center'>{{ teacher.bonus }} {{ teacher.currency }}</div>
                <div class='text-center'>{{ teacher.penalty }} {{ teacher.currency }}</div>
                <div class='text-center'>{{ teacher.load }} {{ teacher.currency }}</div>
                <div class='text-center'>{{ teacher.paid }} {{ teacher.currency }}</div>
                <div class='text-center'>{{ teacher.debt }} {{ teacher.currency }}</div>
                <div class='text-center tsmp-students-body-row-icon'>
                    <b-button
                        type='button'
                        class='mr-2'
                        variant='success'
                        size='sm'
                        @click='callModalTeacherPay(teacher.id, teacher.name)'
                    >
                        Оплатить
                    </b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import TeacherSalaryModalPay from "@/components/admin/teacher-salary/TeacherSalaryModalPay";

export default {
    name: 'TeacherStatisticSalaryTable',
    props: {
        teachers: {
            type: Object,
            default: () => { return {} },
        },
        date: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
    methods: {
        callModalTeacherPay(teacherId, teacherName) {
            this.$modal.show(TeacherSalaryModalPay, {
                form: {
                    teacher_id: teacherId,
                    teacher_name: teacherName,
                    date: {
                        start: dayjs(this.date.start).format('YYYY-MM-DD'),
                        end: dayjs(this.date.end).format('YYYY-MM-DD'),
                    },
                },
            }, {
                name: 'TeacherSalaryModalContainerPay',
                width: '992px',
                height: 'auto',
                scrollable: true,
            }, {
                'before-close': (event) => this.fetchDataAfterModalClose(event),
            });
        },
        async fetchDataAfterModalClose(event) {
            this.$emit('on-refetch', event);
        },
    },
};
</script>

<style lang='scss' scoped>
.tsmp-students-header,
.tsmp-students-body-grid {
    display: grid;
    grid-template-columns: repeat(8, 1fr) 142px;
    align-items: center;
}

.tsmp-students-header {
    margin-left: -20px;
    margin-right: -20px;
    font-weight: 600;
    text-transform: uppercase;

    & > * {
        padding: 12px 0;

        &:first-child {
            padding-left: 20px;
        }
    }
}

.tsmp-students-body-row {
    position: relative;
    align-items: center;
    margin-left: -20px;
    margin-right: -20px;

    &:hover {
        transition: background-color .3s ease;
        background-color: rgba(#798892, 0.08);
    }

    &.active {
        background-color: rgba(#1B3C66, 0.1);

        .tsmp-students-icon {
            transform: rotateX(180deg);
        }
    }
}

.tsmp-students-body-grid {
    & > * {
        padding: 12px 0;

        &:first-child {
            padding-left: 20px;
        }

        &:last-child {
            padding-right: 20px;
        }
    }
}

.tsmp-students-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-decoration-line: none !important;
}

.tsmp-collapse {
    padding: 12px 0;
    cursor: default;
}

.tsmp-collapse-row {
    display: grid;
    grid-template-columns: 180px 120px 82px;
    gap: 8px;

    & > * {
        margin: 6px 0;

        &:first-child {
            padding-left: 20px;
        }
    }
}

.tsmp-collapse .badge,
.tsmp-badge {
    min-width: 82px;
    padding: 6px 12px;
}
</style>