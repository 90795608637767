<template>
    <div class='teacher__details'>
        <Preloader v-if='loader' />
        <h2 class='mb-4'>Статистика зарплат</h2>

        <div class='d-flex items-center w-100 mb-4'>
            <VCalendar
                class='teacher-salaries-section-calendar'
                mode='date'
                is-range
                :popover="{ visibility: 'focus' }"
                :masks="{ input: ['DD.MM.YYYY']}"
                @input='handleDateSelect'
                v-model='date'
            >
                <template v-slot='{ togglePopover }'>
                    <div @click='togglePopover()'>
                        <input
                            class='form-control'
                            :class="{'hidden': !date.start}"
                            :value='`с ${formatDate(date.start)} по ${formatDate(date.end)}`'
                            readonly
                        />
                    </div>
                </template>
            </VCalendar>

            <div class='d-flex justify-content-end ml-auto'>
                <TeacherSalariesTotal class='totals' :total="this.total"/>
            </div>
        </div>

        <TeacherStatisticSalaryTable v-if='teachers && Object.keys(teachers).length' :teachers='teachers' @on-refetch='handleRefetch' :date='date' />
        <div v-else>Нет результатов</div>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import VCalendar from 'v-calendar/lib/components/date-picker.umd';
import TeacherStatisticSalaryTable
    from '@/components/admin/teacher-statistic-salary/TeacherStatisticSalaryTable.vue';
import TeacherSalariesTotal from '@/components/admin/teacher-salary/TeacherSalariesTotal';

export default {
    name: 'TeacherStatisticSalary',
    components: {
        VCalendar,
        TeacherStatisticSalaryTable,
        TeacherSalariesTotal,
        Preloader: () => import('@/components/Preloader'),
    },
    data() {
        return {
            loader: false,
            date: {
                start: dayjs().set('date', 1).format('YYYY-MM-DD'),
                end: dayjs().endOf('month').format('YYYY-MM-DD'),
            },
            teachers: null,
            total: null,
        };
    },
    async created() {
        await this.dataFetching();
    },
    methods: {
        async handleDateSelect() {
            await this.dataFetching();
        },
        async handleRefetch(event) {
            if (event.params && "refetch" in event.params && event.params.refetch) {
                await this.dataFetching();
            }

            return false;
        },
        async dataFetching() {
            this.loader = true;
            await Promise.all([
                this.fetchTeachers(),
                this.fetchTotal()
            ]);
            this.loader = false;
        },
        async fetchTotal() {
            try {
                const {data} = await this.$axios.get(`crm/teachers/teacher-salary/total`, {params: {
                        date_from: this.formatDate(this.date.start),
                        date_to: this.formatDate(this.date.end),
                    }});
                this.total = data;
            } catch (ex) {
                console.log('cant fetch teacher salary total: ' + ex);
            }
        },
        async fetchTeachers() {
            try {
                const {data} = await this.$axios.get(`crm/teachers/salary-list`, {params: {
                        date_from: this.formatDate(this.date.start),
                        date_to: this.formatDate(this.date.end),
                    }});
                this.teachers = data;
            } catch (ex) {
                console.log('cant fetch teachers salary: ' + ex);
            }
        },
        // callModalPayAll() {
        //     this.$modal.show(TeacherStatisticSalaryPayAll, {
        //         form: {
        //             payment_type: 0,
        //             date: dayjs().format('YYYY-MM-DD'),
        //             price: 0,
        //             comment: null,
        //         },
        //     }, {
        //         name: 'TeacherStatisticSalaryPayAll',
        //         width: '992px',
        //         height: 'auto',
        //         scrollable: false,
        //     });
        // },
        formatDate(date) {
            return dayjs(date).format('DD.MM.YYYY');
        },
    },
};
</script>

<style lang='scss' scoped>
::v-deep {
    .form__group {
        justify-content: flex-end;

        label {
            margin: 0;
        }
    }

    .totals {
        .list-group {
            margin-right: 0 !important;
        }
    }
}

.teacher-salaries-section-calendar {
    min-width: 328px;

    input {
        padding: 0.75rem 1.25rem;
        height: auto;
    }
}
</style>